<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type=='view'?$t('cip.desk.notice.title.viewHeadTitle'):$t('cip.desk.notice.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {submit, getVideoPlay,start,stop,get} from "@/api/desk/videoPlay";

export default {
  name: "videoPlayEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
      isStart:true,
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: '名称',
          prop: 'name',
          span: 8,
          disabled: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: "名称不能为空",
              trigger: "blur"
            },
          ],
        },
        {
          label: 'RTSP协议地址',
          prop: 'rtspUrl',
          labelWidth: 150,
          span: 16,
          disabled: !['add', 'edit'].includes(this.type),
          placeholder: "请输入rtsp为开头的RTSP协议地址",
          rules: [
            {
              required: true,
              message: "地址不能为空",
              trigger: "blur"
            },
          ],
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (this.type != 'add') {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getVideoPlay(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },

    headSave(status,cancel = false) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          submit({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              this.$refs.formLayout.dataForm = res.data
              if (res.data.code == 200 && status=="1") {
                this.$message.success("刷新成功")
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
